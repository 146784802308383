import { graphql } from 'gatsby'

import { withDispensaryPageAdapter } from '../../../../hoc/withDispensaryPageAdapter'
import AccountRoute from '../../../account/[...]'

// NOTE: keep this query synchronized with the one in ../../../account/[...].tsx
export const query = graphql`
  query DispensaryAccountPage {
    ...rewardsLevelImage
  }
`

export default withDispensaryPageAdapter(AccountRoute)
